
//https://stackoverflow.com/questions/37318808/what-is-the-in-place-alternative-to-array-prototype-filter
export const FilterInPlace = (array: any[], condition: (val: any, index?: number, array?: any[]) => boolean) => {
    let index = 0, j = 0;

    while (index < array.length) {
        const val = array[index];
        if (condition(val, index, array)) array[j++] = val;
        index++;
    }

    array.length = j;
    return array;
}

export const RemoveItemFromArray = (functionList:any[], removeItem:any) => { 
    functionList.splice(functionList.indexOf(removeItem), 1);
}

export const StringPropertySort = (stringArray: any[], propertyName: string) : any[] => {
    return stringArray.sort((a, b) => {
        if (a[propertyName] < b[propertyName])
            return -1;
        if (a[propertyName] > b[propertyName])
            return 1;
        return 0;
    })
}

export function GetDistinctElementCounts<T>(arr: T[]): Map<T, number> {
    const map = new Map<T, number>();
    arr.forEach(el => {
        if (!map.has(el)) map.set(el, 1)
        else map.set(el, map.get(el) + 1)
    })
    return map;
}